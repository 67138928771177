import "./abonnement.css";
import React, { useState, useEffect } from "react";
import checkingGif from "../images/check.gif";
import { getJwtToken } from "../auth";
import config from "../config";

import ErrorNotification from '../notifications/Error';
import Neutral from '../notifications/Neutral';
import Succes from '../notifications/Succes';

const reactServer = config.reactServer;

const Abonnement: React.FC = () => {
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [neutral, setNeutral] = useState('');
  const [succes, setSucces] = useState('');
  const [actionMessage, setActionMessage] = useState<string>("");
  const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
  const [licenseAgreementChecked, setLicenseAgreementChecked] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [contactFormData, setContactFormData] = useState({
    fullName: "",
    email: "",
    institution: "",
    function: "",
    userCount: "",
  });

  // Define the subscription options
  const subscriptionOptions = [
    {
      pricing_id: 2,
      code: "pro",
      name: "Zorgverlener Go",
      customer_type: "Zorgverlener",
      price: "€29,99/maand",
      features: [
        "30 Chats/maand*",
        "15 minuten per Chat",
        "Samenvattingen",
        "1 gebruiker",
      ],
      price_num: 29.99,
      type: "month",
      limit: 30,
    },
    {
      pricing_id: 3,
      code: "plus",
      name: "Zorgverlener Plus",
      customer_type: "Zorgverlener",
      price: "€59,99/maand",
      features: [
        "100 Chats/maand*",
        "15 minuten per Chat",
        "Samenvattingen",
        "1 gebruiker",
      ],
      price_num: 59.99,
      type: "month",
      limit: 100,
    },
    {
      pricing_id: 4,
      code: "business_pro",
      name: "Zorginstelling Go",
      customer_type: "Zorginstelling",
      price: "€59,99/maand per gebruiker",
      price_num: 59.99,
      features: [
        "100 Chats/maand",
        "15 minuten per Chat",
        "Samenvattingen",
        "Tot 10 gebruikers",
      ],
    },
    {
      code: "groot_gebruiker",
      name: "Zorginstelling Plus",
      customer_type: "Zorginstelling",
      price: "€49,99/maand per gebruiker",
      features: ["Vanaf 11 of meer gebruikers"],
      price_num: 49.99,
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchProfile();
  }, []);

  const fetchProfile = () => {
    const token = getJwtToken();

    if (token) {
      fetch(`${reactServer}account/profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            // throw new Error(`HTTP error! Status: ${response.status}`);
            setErrorMessage('Er is iets misgegaan bij het ophalen van uw profiel.');
          }
          return response.json();
        })
        .then((data) => {
          setProfile(data);
        })
        .catch((error) => {
          // setError(`Error fetching profile: ${error.message}`);
          setErrorMessage('Er is iets misgegaan bij het ophalen van uw profiel.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Stop subscription
  const stopSubscription = async () => {
    const confirmed = window.confirm(
      "Weet je zeker dat je je abonnement wil stoppen? De betaling van deze maand zal nog wel worden afgenomen. Als je doorgaat, verlies je direct de toegang tot alle functies na deze periode."
    );
    if (!confirmed) {
      return;
    }

    const token = getJwtToken();
    const response = await fetch(`${reactServer}account/stopsubcription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: profile.id }),
    });

    if (response.ok) {
      setActionMessage("Je abonnement is stopgezet.");
      fetchProfile();
    } else {
      setActionMessage("Fout bij het stopzetten van het abonnement.");
    }
  };

  // Pause subscription
  const pauseSubscription = async () => {
    const token = getJwtToken();
    const response = await fetch(`${reactServer}account/pause-subscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setActionMessage("Je abonnement is gepauzeerd.");
      fetchProfile();
    } else {
      setActionMessage("Fout bij het pauzeren van het abonnement.");
    }
  };

  // Select a subscription for upgrade/change
  const selectSubscription = (subscriptionCode: string) => {
    const option = subscriptionOptions.find(
      (option) => option.code === subscriptionCode
    );
    setSelectedSubscription(option);
    setLicenseAgreementChecked(false);
    setOrganizationName("");
    setActionMessage("");
    setContactFormData({
      fullName: "",
      email: "",
      institution: "",
      function: "",
      userCount: "",
    });
  };

  // Handle payment
  const handlePayment = async () => {
    setPaymentProcessing(true);

    let body: any = {
      subscriptionType: "monthly",
      customer_type: selectedSubscription.customer_type,
      amount: selectedSubscription.price_num,
      license: selectedSubscription.name,
      pricing_id: selectedSubscription.pricing_id,
      organisationName: organizationName,
    };

    const token = getJwtToken();
    const response = await fetch(`${reactServer}payments/create-payment`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      response
        .json()
        .then((responseBody) => {
          const linkToLoad = responseBody.paymentUrl;

          if (linkToLoad) {
            window.location.href = linkToLoad;
          } else {
            setActionMessage("Geen geldige link ontvangen om te laden.");
          }
          fetchProfile();
        })
        .catch((error) => {
          console.error("Error parsing response body:", error);
          setErrorMessage('Er is iets misgegaan bij het verwerken van de betaling.');
        });
    } else {
      setActionMessage("Fout bij het verwerken van de betaling.");
    }
    setPaymentProcessing(false);
  };

  // Contact form submit for groot_gebruiker
  const handleContactFormSubmit = () => {
    setActionMessage(
      "Uw aanvraag is verstuurd. We nemen zo snel mogelijk contact met u op."
    );
    setSelectedSubscription(null);
  };

  // Go back to subscription options
  const goBackToOptions = () => {
    setSelectedSubscription(null);
    setLicenseAgreementChecked(false);
    setOrganizationName("");
    setActionMessage("");
    setContactFormData({
      fullName: "",
      email: "",
      institution: "",
      function: "",
      userCount: "",
    });
  };

  if (loading) {
    return (
      <div className="loading-container">
        <img src={checkingGif} alt="Loading..." />
      </div>
    );
  }

  // if (error) {
  //   return <div className="error-message">{error}</div>;
  // }

  // profile.subscription is an array of subscription objects
  const subscriptions = Array.isArray(profile.subscription)
    ? profile.subscription
    : profile.subscription
    ? [profile.subscription]
    : [];

  // Find the active subscription
  let activeSubscription = subscriptions
    .filter((sub: any) => sub.pricing_id !== 1)
    .find((sub: any) => sub.status === "active");
  if (!activeSubscription) {
    // If no active subscription was found, include the pricing_id == 1
    activeSubscription = subscriptions.find(
      (sub: any) => sub.status === "active"
    );
  }

  const activeSubscriptionName = activeSubscription
    ? activeSubscription.pricing.name
    : null;
  const activeSubscriptionEndDate = activeSubscription
    ? new Date(activeSubscription.end_date).toLocaleDateString()
    : null;

  const isOrganizationAdmin = !!profile.org_id;
  const isZorgverlenerSingleUser =
    !isOrganizationAdmin &&
    (activeSubscriptionName === "Zorgverlener Go" ||
      activeSubscriptionName === "Zorgverlener Plus");

  // Check if user is on Demo
  const isDemo = activeSubscriptionName === "Demo";

  // Filter available subscriptions based on the active subscription name
  const currentSubName = activeSubscriptionName || "";
  let availableSubscriptions = subscriptionOptions.filter(
    (option) => option.name !== currentSubName
  );

  if (isOrganizationAdmin) {
    // If org admin, show only Zorginstelling Plus
    availableSubscriptions = availableSubscriptions.filter(
      (option) => option.code === "groot_gebruiker"
    );
  }

  // Calculate demo usage if Demo subscription
  const demoUsage = profile?.usage?.[0] || 0;
  const demoLimit = 5; // 5 free Chats
  const demoRemaining = demoLimit - demoUsage;
  const demoMessage =
    demoUsage >= demoLimit
      ? "U heeft alle 5 gratis Chats van de Demo gebruikt."
      : `U heeft ${demoUsage} van de ${demoLimit} gratis Chats gebruikt.`;

  const handleCloseError = () => {
    setErrorMessage('');
  };

  const handleCloseNeutral = () => {
    setNeutral('');
  };

  const handleCloseSucces = () => {
    setSucces('');
  };


  return (
    <div className="abbonement-page">
            {error && <ErrorNotification message={errorMessage} onClose={handleCloseError} />}
            {neutral && <Neutral message={neutral} onClose={handleCloseNeutral} />}
            {succes && <Succes message={succes} onClose={handleCloseSucces} />}
      <div
        className={`abonnement-page-container ${
          selectedSubscription || isOrganizationAdmin ? "narrow" : ""
        }`}
      >
        {selectedSubscription ? (
          selectedSubscription.code === "groot_gebruiker" ? (
            <>
              <button className="back-button" onClick={goBackToOptions}>
                Terug
              </button>
              <p className="selection-title">
                Met dit formulier komt u in contact met ons voor het aanmaken
                van een account met meer dan 10 gebruikers. Wij zullen uw
                aanvraag zo snel mogelijk behandelen.
              </p>
              <div className="contact-form">
                <div className="form-group">
                  <label htmlFor="fullName">Voor- en Achternaam*</label>
                  <input
                    type="text"
                    id="fullName"
                    value={contactFormData.fullName}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        fullName: e.target.value,
                      })
                    }
                    placeholder="Vul uw voornaam in"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">E-mailadres*</label>
                  <input
                    type="email"
                    id="email"
                    value={contactFormData.email}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        email: e.target.value,
                      })
                    }
                    placeholder="Vul uw e-mailadres in"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="institution">
                    Zorginstelling/Ziekenhuis*
                  </label>
                  <input
                    type="text"
                    id="institution"
                    value={contactFormData.institution}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        institution: e.target.value,
                      })
                    }
                    placeholder="Werkzaam bij"
                  />
                </div>
                <div className="form-group">
                  <label>Functie*</label>
                  <div className="radio-group">
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Arts (Specialist)"
                        checked={
                          contactFormData.function === "Arts (Specialist)"
                        }
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Arts (Specialist)</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Verpleegkundige"
                        checked={contactFormData.function === "Verpleegkundige"}
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Verpleegkundige</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Co-assistent"
                        checked={contactFormData.function === "Co-assistent"}
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Co-assistent</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Overige zorgverleners"
                        checked={
                          contactFormData.function === "Overige zorgverleners"
                        }
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Overige zorgverleners</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Directie/Management"
                        checked={
                          contactFormData.function === "Directie/Management"
                        }
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Directie/Management</span>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="userCount">
                    Geef een indicatie voor het aantal gebruikers dat u verwacht
                    te willen gebruiken
                  </label>
                  <input
                    type="number"
                    id="userCount"
                    value={contactFormData.userCount}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        userCount: e.target.value,
                      })
                    }
                  />
                </div>
                <button
                  className="action-button submit-button"
                  onClick={handleContactFormSubmit}
                  disabled={
                    !contactFormData.fullName.trim() ||
                    !contactFormData.email.trim() ||
                    !contactFormData.institution.trim() ||
                    !contactFormData.function
                  }
                >
                  Verstuur aanvraag
                </button>
              </div>
            </>
          ) : (
            <>
              <button className="back-button" onClick={goBackToOptions}>
                Terug
              </button>
              <h2 className="selection-title">
                U heeft gekozen voor het volgende abonnement:
              </h2>
              <div className="subscription-selection">
                <div className="subscription-card selected-card">
                  <h3>{selectedSubscription.name}</h3>
                  <p className="subscription-price">
                    {selectedSubscription.price}
                  </p>
                  <ul className="subscription-features">
                    {selectedSubscription.features.map(
                      (feature: string, index: number) => (
                        <li key={index}>{feature}</li>
                      )
                    )}
                  </ul>
                </div>
                {selectedSubscription.code === "business_pro" && (
                  <div className="organization-input">
                    <label htmlFor="organizationName">
                      Naam van uw organisatie:
                    </label>
                    <input
                      type="text"
                      id="organizationName"
                      value={organizationName}
                      onChange={(e) => setOrganizationName(e.target.value)}
                    />
                  </div>
                )}
                <div className="license-agreement">
                  <input
                    type="checkbox"
                    id="licenseAgreement"
                    checked={licenseAgreementChecked}
                    onChange={(e) =>
                      setLicenseAgreementChecked(e.target.checked)
                    }
                  />
                  <label htmlFor="licenseAgreement">
                    Ik heb de &nbsp;
                    {selectedSubscription.name === "Zorgverlener Go" && (
                      <a
                        href="/./bestanden/Licenses/Zorgverlener_Go.pdf"
                        download
                      >
                        licentieovereenkomst
                      </a>
                    )}
                    {selectedSubscription.name === "Zorgverlener Plus" && (
                      <a
                        href="/./bestanden/Licenses/Zorgverlener_Plus.pdf"
                        download
                      >
                        licentieovereenkomst
                      </a>
                    )}
                    {selectedSubscription.name === "Zorginstelling Go" && (
                      <a
                        href="/./bestanden/Licenses/Zorginstelling_Go.pdf"
                        download
                      >
                        licentieovereenkomst
                      </a>
                    )}
                    &nbsp;&nbsp;gelezen en ik ga akkoord met de
                    licentieovereenkomst.
                  </label>
                </div>
                <button
                  className="action-button payment-button"
                  onClick={handlePayment}
                  disabled={
                    !licenseAgreementChecked ||
                    (selectedSubscription.code === "business_pro" &&
                      !organizationName.trim())
                  }
                >
                  {paymentProcessing
                    ? "Bezig met verwerken..."
                    : "Ga naar betaling"}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            <h2 className="abonnement-title">Abonnement Details</h2>
            {activeSubscription ? (
              <div className="abonnement-details">
                <p className="abonnement-info">
                  <strong>Huidige Abonnementstype:</strong>{" "}
                  {activeSubscriptionName}
                </p>
                <p className="abonnement-info">
                  <strong>Abonnement geldig tot:</strong>{" "}
                  {activeSubscriptionEndDate}
                </p>

                {/* If it's a Demo subscription, show usage info */}
                {isDemo && <p className="abonnement-info">{demoMessage}</p>}

                {isOrganizationAdmin ? (
                  <p className="organization-message">
                    Neem contact op met uw organisatie om uw abonnement te
                    stoppen.
                  </p>
                ) : (
                  <div className="user-actions">
                    {/* If Zorgverlener single user */}
                    {isZorgverlenerSingleUser && (
                      <button
                        className="action-button stop-button"
                        onClick={stopSubscription}
                      >
                        Abonnement Stopzetten
                      </button>
                    )}
                    {/* If it's Demo or other subscription and user is single and not Zorgverlener */}
                    {!isZorgverlenerSingleUser && !isDemo && (
                      <button
                        className="action-button pause-button"
                        onClick={pauseSubscription}
                      >
                        Pauzeer Abonnement
                      </button>
                    )}
                    {/* For Demo subscription, no stop or pause needed, but you can decide what to show */}
                    {isDemo && demoUsage >= demoLimit && (
                      <p style={{ marginTop: "10px", color: "red" }}>
                        Uw gratis Chats zijn op. Kies een betaalde abonnement om
                        verder te gaan.
                      </p>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <p>Geen actief abonnement gevonden.</p>
            )}

            {profile &&
              !selectedSubscription &&
              availableSubscriptions.length > 0 && (
                <div
                  className={`subscription-options ${
                    availableSubscriptions.length === 1 ? "single-option" : ""
                  }`}
                >
                  {availableSubscriptions.map((option) => (
                    <div
                      className={`subscription-card ${
                        availableSubscriptions.length === 1 ? "single-card" : ""
                      }`}
                      key={option.code}
                    >
                      <h3>Upgraden naar {option.name}</h3>
                      <p className="subscription-price">{option.price}</p>
                      <ul className="subscription-features">
                        {option.features.map((feature, index) => (
                          <li key={index}>{feature}</li>
                        ))}
                      </ul>
                      <button
                        className="action-button upgrade-button"
                        onClick={() => selectSubscription(option.code)}
                      >
                        {option.code === "groot_gebruiker"
                          ? "Contact opnemen"
                          : `Kies ${option.name}`}
                      </button>
                    </div>
                  ))}
                </div>
              )}
          </>
        )}

        {actionMessage && <p className="action-message">{actionMessage}</p>}
      </div>
      <div className="spacer"></div>
    </div>
  );
};

export default Abonnement;

import React, { useState, useEffect, useRef } from "react";
import "./miccheckbutton.css";

/**
 * This component shows:
 *  - A microphone icon & tooltip
 *  - A modal that lists microphones in a dropdown
 *  - A live audio volume meter to show mic activity
 */
const MicCheckButton: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const [micDevices, setMicDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedMicId, setSelectedMicId] = useState<string>("");
    const [permissionDenied, setPermissionDenied] = useState(false);

    const [showNoMicTooltip, setShowNoMicTooltip] = useState(false);

    // Volume meter references
    const audioContextRef = useRef<AudioContext | null>(null);
    const analyserRef = useRef<AnalyserNode | null>(null);
    const dataArrayRef = useRef<Uint8Array | null>(null);
    const [volume, setVolume] = useState(0); // Store a numeric value 0..100

    const stripesCount = 30;            // The total number of stripes
    const stripesLit = Math.round(volume / (100 / stripesCount));

    // Keep track of the current media stream so we can stop it if user switches mics
    const currentStreamRef = useRef<MediaStream | null>(null);

    const openModal = async () => {
        setIsModalOpen(true);
        try {
            // 1. Attempt to request mic permission with a "temporary" stream:
            const tempStream = await navigator.mediaDevices.getUserMedia({
                audio: true
            });
            // If we get here, user allowed microphone access
            setPermissionDenied(false);

            // Close/stop that temp stream
            tempStream.getTracks().forEach((track) => track.stop());

            // 2. Enumerate all audio input devices:
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioInputs = devices.filter((d) => d.kind === "audioinput");

            // 3. If no audio devices found, or user’s system has none
            if (audioInputs.length === 0) {
                setMicDevices([]);
                setSelectedMicId("");
                return;
            }

            setMicDevices(audioInputs);
            setSelectedMicId(audioInputs[0].deviceId); // default to the first mic
        } catch (err) {
            console.error("MicCheckButton: Permission denied or error:", err);
            // If we fail here, user has blocked access or there's an error
            setPermissionDenied(true);
            setMicDevices([]);
            setSelectedMicId("");
        }
    };

    // Whenever the user changes the selectedMicId, re-init the audio stream
    useEffect(() => {
        if (!isModalOpen || !selectedMicId) {
            // If modal is closed or no device selected, do nothing
            stopCurrentStream();
            return;
        }

        // Start capturing audio
        startAudioStream(selectedMicId);

        return () => {
            stopCurrentStream();
        };
    }, [selectedMicId, isModalOpen]);

    const stopCurrentStream = () => {
        // Stop any previous audio track
        if (currentStreamRef.current) {
            currentStreamRef.current.getTracks().forEach((track) => {
                track.stop();
            });
        }

        // Close down the audio context
        if (audioContextRef.current) {
            audioContextRef.current.close().catch((err) => {
                console.warn("Error closing audio context:", err);
            });
            audioContextRef.current = null;
            analyserRef.current = null;
            dataArrayRef.current = null;
        }
    };

    const startAudioStream = async (deviceId: string) => {
        try {
            // Request a MediaStream from the chosen device
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId },
            });
            currentStreamRef.current = stream;

            // Create an audio context + analyser node
            const audioContext = new AudioContext();
            audioContextRef.current = audioContext;

            const source = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();

            // Set up the analyser
            analyser.fftSize = 512; // smaller = more frequent updates
            analyserRef.current = analyser;

            // The data array for raw audio data
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            dataArrayRef.current = dataArray;

            // Connect the source to the analyser
            source.connect(analyser);

            // Start the animation loop for volume
            updateVolumeMeter();
        } catch (error) {
            console.error("Error starting audio stream:", error);
        }
    };

    const lastSmoothedRef = useRef(0);

    const updateVolumeMeter = () => {
        if (!analyserRef.current || !dataArrayRef.current) return;

        const analyser = analyserRef.current;
        const dataArray = dataArrayRef.current;

        analyser.getByteTimeDomainData(dataArray);

        // 1) Calculate RMS or peak as you already do
        let sum = 0;
        for (let i = 0; i < dataArray.length; i++) {
            const v = (dataArray[i] - 128) / 128; // from -1..1
            sum += v * v;
        }
        const rms = Math.sqrt(sum / dataArray.length);
        let newVolume = Math.min(100, Math.floor(rms * 1000)); // 0..100

        // 2) Apply exponential smoothing
        // A smoothingFactor near 1.0 => slower changes,
        // near 0 => almost no smoothing (snappy).
        const smoothingFactor = 0.75;
        const lastSmoothed = lastSmoothedRef.current;
        const smoothedVolume = lastSmoothed * smoothingFactor + newVolume * (1 - smoothingFactor);

        // 3) Set your state to this smoothed value
        setVolume(Math.round(smoothedVolume));

        // 4) Remember this value for the next frame
        lastSmoothedRef.current = smoothedVolume;

        // 5) Schedule the next frame
        requestAnimationFrame(updateVolumeMeter);
    };

    const closeModal = () => {
        stopCurrentStream();
        setIsModalOpen(false);
    };

    // True if no mic or permission denied
    const noMicOrDenied = permissionDenied || micDevices.length === 0;

    function truncateLabel(label: string, maxLength = 35) {
        return label.length > maxLength ? label.slice(0, maxLength) + "..." : label;
    }

    return (
        <div
            className="mic-check-button-container"
        >
            {/* Microphone Icon Button */}
            <button 
                className="mic-button" 
                onClick={openModal}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <img src="/microphone.png" alt="Mic Check" />
                {showTooltip && (
                    <div className="tooltip">Check voor de aanwezigheid van een microfoon</div>
                )}
            </button>
            {noMicOrDenied && (
                <span
                    className="red-info-icon-container"
                    onClick={() => setShowNoMicTooltip(!showNoMicTooltip)}
                    onMouseEnter={() => setShowNoMicTooltip(true)}
                    onMouseLeave={() => setShowNoMicTooltip(false)}
                >
                    i
                    {showNoMicTooltip && (
                        <div className="red-info-tooltip">
                            Er is geen microfoon aangetroffen of de toegang hiertoe is geweigerd.
                            De audio opname voor de transcriptie zal niet werken totdat er een
                            werkende microfoon wordt aangetroffen op uw toestel.
                        </div>
                    )}
                </span>
            )}

            {/* Modal */}
            {isModalOpen && (
                <div className="mic-modal-overlay">
                    <div className="mic-modal">
                        <h2>Microfoon</h2>
                        {/* Check if permission is denied or no devices found */}
                        {permissionDenied || micDevices.length === 0 ? (
                            <p style={{ color: "red" }}>
                                Geen microfoons gevonden of toestemming geweigerd.
                            </p>
                        ) : (
                            /* If permission allowed & we have devices, show the dropdown etc. */
                            <div>
                                <label>
                                    Geselecteerde microfoon:
                                    <select
                                        className="selector"
                                        value={selectedMicId}
                                        onChange={(e) => setSelectedMicId(e.target.value)}
                                    >
                                        {micDevices.map((device) => (
                                            <option key={device.deviceId} value={device.deviceId} title={device.label}>
                                                {`${truncateLabel(device.label)}` || `Microfoon ${micDevices.indexOf(device) + 1}`}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                                <br />
                                <div className="volume-meter-container">
                                    <p>Microfoonindicatie:</p>
                                    <div className="meter-back">
                                        <div
                                            className="meter-fill"
                                            style={{ width: `${volume}%` }}
                                        ></div>
                                    </div>
                                    {/* <p style={{ fontSize: "0.9rem" }}>
                                        Volume: {volume}%
                                    </p> */}
                                    {/* Horizontal stripes */}
                                    <div className="stripes-container">
                                        {Array.from({ length: stripesCount }, (_, i) => {
                                            const stripeIndex = i + 1;
                                            const isActive = stripeIndex <= stripesLit;
                                            return (
                                                <div
                                                    key={stripeIndex}
                                                    className={`stripe ${isActive ? "active" : ""}`}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        <button className="cancel-button" onClick={closeModal}>
                            Sluiten
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MicCheckButton;
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Registration.css";

import Error from "../notifications/Error";
import Succes from "../notifications/Succes";

import eyeOpen from "../images/eye_open.png";
import eyeClosed from "../images/eye_closed.png";

import config from "../config";
const reactServer = config.reactServer;

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [wachtwoord, setWachtwoord] = useState("");
  const [error, setError] = useState("");
  const [succes, setSucces] = useState("");
  const [twoStepAvailable, setTwoStepAvailable] = useState(false);
  const [twoStepCode, setTwoStepCode] = useState(Array(6).fill(""));

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = {
      email: email,
      wachtwoord: wachtwoord,
    };

    try {
      const response = await fetch(reactServer + "account/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const responseData = await response.json();
        setError(responseData.message);
        return;
      }

      const data = await response.json();

      if (response.status === 201) {
        setSucces(data.message);
        setTwoStepAvailable(true);
      } else if (response.status === 200) {
        const token = data.access_token;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("email", email.toLowerCase());
        navigate("/", { replace: true });
        window.location.reload();
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.log(error);
      setError("Geen verbinding met de server.");
    }
  };

  const handleVerification = async (email: string, code: string) => {
    const formdata = {
      email: email.toLowerCase(),
      code: code,
    };

    try {
      const response = await fetch(reactServer + "account/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });

      if (!response.ok) {
        const responseData = await response.json();
        setError(responseData.message);
        return;
      }

      const data = await response.json();

      if (response.ok) {
        const token = data.access_token;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("email", email.toLowerCase());
        navigate("/", { replace: true });
        window.location.reload();
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("Geen verbinding met de server.");
    }
  };

  useEffect(() => {
    // Automatically submit when all fields are filled
    if (twoStepCode.every((char) => char !== "")) {
      handleVerificationSubmit();
    }
  }, [twoStepCode]);

  const handleVerificationSubmit = async () => {
    const code = twoStepCode.join("");
    await handleVerification(email, code);
  };

  const handleCodeChange = (index: number, value: string) => {
    if (value.length <= 1 && /^[0-9]?$/.test(value)) {
      const newCode = [...twoStepCode];
      newCode[index] = value;
      setTwoStepCode(newCode);

      // Focus the next input field
      if (value && index < 5) {
        (document.getElementById(
          `code-${index + 1}`
        ) as HTMLInputElement).focus();
      }
    }
  };

  const handleCloseError = () => {
    setError("");
  };

  const handleCloseSucces = () => {
    setSucces("");
  };

  return (
    <div className="login-container">
      {error && <Error message={error} onClose={handleCloseError} />}
      {succes && <Succes message={succes} onClose={handleCloseSucces} />}
      <h1 className="page-title">Login</h1>
      <form className="registration-form" onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <div className="password-field">
          <input
            type={showPassword ? "text" : "password"}
            autoComplete="new-password"
            name="wachtwoord"
            placeholder="Wachtwoord"
            value={wachtwoord}
            onChange={(event) => setWachtwoord(event.target.value)}
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? "Verberg wachtwoord" : "Toon wachtwoord"}
          >
            <img
              src={showPassword ? eyeClosed : eyeOpen}
              alt={showPassword ? "Verberg wachtwoord" : "Toon wachtwoord"}
              className="eye-icon"
            />
          </button>
        </div>
        <button type="submit" className="submit-button">
          Login
        </button>
        {twoStepAvailable && (
          <div className="two-step-container">
            <label className="verificatie-text" htmlFor="termsAndServices">
              Uw tweestaps verificatie code:
            </label>
            <div className="two-step-inputs">
              {twoStepCode.map((digit, index) => (
                <input
                  key={index}
                  id={`code-${index}`}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(event) =>
                    handleCodeChange(index, event.target.value)
                  }
                  className="two-step-box"
                />
              ))}
            </div>
          </div>
        )}
      </form>
      <div className="bottomblock">
        <div className="same-line login-pagina-tekst">
          Heb je nog geen account?{" "}
          <Link className="link" to="/register">
            Registreren
          </Link>
        </div>
        <div className="same-line login-pagina-tekst">
          <Link className="link" to="/wachtwoord-vergeten">
            Wachtwoord vergeten?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
